import { render, staticRenderFns } from "./credit-record-detail.vue?vue&type=template&id=01edf4ae&scoped=true"
import script from "./credit-record-detail.vue?vue&type=script&lang=js"
export * from "./credit-record-detail.vue?vue&type=script&lang=js"
import style0 from "./credit-record-detail.vue?vue&type=style&index=0&id=01edf4ae&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01edf4ae",
  null
  
)

export default component.exports