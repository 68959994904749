<template>
  <div class="record-detail-container">
    <div class="r-top">
      <p class="header">{{ $t('home_page_title11') }}</p>
      <div class="content">
        <p>
          <span>{{ $t('home_page_title13') }}</span>
          <span>{{ formatAmount(remain_obj, 'limit') }}</span>
        </p>
        <p>
          <span>{{ $t('home_page_title14') }}</span>
          <span>{{ formatAmount(remain_obj, 'used') }}</span>
        </p>
        <p>
          <span>{{ $t('home_page_title15') }}</span>
          <span>{{ formatAmount(remain_obj, 'balance') }}</span>
        </p>
      </div>
    </div>
    <div class="r-bottom">
      <p class="header">{{ $t('credit_record_detail_msg1') }}</p>
      <el-table size="small" :data="record_list">
        <el-table-column
          :label="$t('credit_record_detail_msg2')"
          prop="index_"
          width="110px"
        ></el-table-column>
        <el-table-column
          :label="$t('credit_record_detail_msg3')"
          prop="dealDate"
          min-width="130px"
        ></el-table-column>
        <el-table-column
          :label="$t('home_page_title18')"
          prop="ccyPair"
          width="110"
        ></el-table-column>
        <el-table-column
          :label="$t('credit_record_detail_msg4')"
          prop="txnData"
          width="110"
        ></el-table-column>
        <el-table-column
          :label="$t('credit_record_detail_msg5')"
          prop="contraData"
          width="110"
        ></el-table-column>
        <el-table-column
          :label="$t('credit_record_detail_msg6')"
          prop="valueDate"
          width="110"
        ></el-table-column>
        <el-table-column
          :label="$t('credit_record_detail_msg7')"
          prop="rate"
          width="130"
        ></el-table-column>
        <el-table-column
          :label="$t('credit_record_detail_msg8')"
          prop="status"
          width="130"
        >
          <template slot-scope="scope">
            {{
              scope.row.status.toString() === '0'
                ? $t('credit_record_detail_msg9')
                : scope.row.status.toString() === '1'
                ? $t('credit_record_detail_msg10')
                : scope.row.status.toString() === '2'
                ? $t('credit_record_detail_msg11')
                : scope.row.status.toString() === '3'
                ? $t('credit_record_detail_msg12')
                : '-'
            }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :total="pages.total"
        style="margin-top: 20px"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  getExchangeRecordList,
  getRemainAmount,
} from 'views/main/home-main/api';
export default {
  name: 'credit-record-detail',
  data() {
    return {
      record_list: [],
      remain_obj: null,
      pages: {
        pageSize: 10,
        pageNum: 1,
      },
    };
  },
  mounted() {
    this._getRecordList();
    this._getRemainAmount();
  },
  methods: {
    async _getRecordList() {
      const _params = {
        ...this.pages,
        isCredit: true,
      };
      const { dataSet, pages } = await getExchangeRecordList(_params);
      dataSet.forEach(
        (o, i) => (o['index_'] = (pages.pageNum - 1) * 10 + i + 1),
      );
      this.record_list = dataSet;
      this.pages = pages;
    },
    handleCurrentChange(val) {
      this.pages = { ...this.pages, pageNum: val };
      this._getRecordList();
    },
    async _getRemainAmount() {
      this.remain_obj = await getRemainAmount();
    },
    formatAmount(obj, key) {
      if (obj) {
        return this.$utils.formatAmount(obj[key]);
      }
      return '-';
    },
  },
};
</script>

<style scoped lang="scss">
.record-detail-container {
  width: 100%;
  background: #f0f2f5;
  height: 100%;
  box-sizing: border-box;
  padding: 24px;
  & > div {
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0px 0px 4px 0px rgba(0, 21, 41, 0.12);
  }
  .r-top,
  .r-bottom {
    .header {
      height: 40px;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      font-size: 16px;
      border-bottom: 1px solid #e9e9e9;
    }
  }

  .r-top {
    .content {
      display: flex;
      justify-content: space-around;
      & > p {
        span {
          display: block;
        }
        span:nth-child(2) {
          margin-top: 8px;
          font-size: 20px;
        }
      }
      & > p:nth-child(2) {
        span:nth-child(2) {
          color: #4dcb73;
        }
      }
      & > p:nth-child(3) {
        span:nth-child(2) {
          color: #f5222d;
        }
      }
    }
  }
}
</style>
